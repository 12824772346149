var site = window.site || {};
var openSalesforceChat = window.openSalesforceChat || function () {};

(function ($, Drupal) {
  Drupal.behaviors.liveChatServiceDrawer = {
    attach: function () {
      var rendered =
        $('.domain-fr.device-mobile').length === 0
          ? site.template.get({ name: 'lc_service_drawer_v1', data: {} })
          : '';

      $('body').append(rendered);

      var $lcServiceDrawer = $('.js-lc_service_drawer');
      var $pageOverlay = $('.lc_service_drawer__overlay');

      function closeLcServiceDrawer() {
        $lcServiceDrawer.slideUp();
        $pageOverlay.slideUp();
      }

      function openLcServiceDrawer() {
        $lcServiceDrawer.slideDown();
        $pageOverlay.slideDown();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat offered',
            event_label: 'click'
          });
        }
      }

      function initCSChat() {
        $('.lp_minimize').trigger('click');
        closeLcServiceDrawer();
        openSalesforceChat();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat type selection',
            event_label: 'Customer Service'
          });
        }
      }

      $('.js-live-chat').on('click', function (event) {
        event.stopPropagation();
        event.preventDefault();

        if ($lcServiceDrawer.is(':visible')) {
          closeLcServiceDrawer();

          return;
        }

        if (!($(this).data('chat-type') !== 'disabled' && typeof lpTag !== 'undefined')) {
          initCSChat();
          return;
        }

        if ($('body').find('.js-lc_service_drawer').length === 0) {
          $('body').append(rendered);
        }
        openLcServiceDrawer();
      });

      $pageOverlay.on('click', function (event) {
        event.stopPropagation();
        closeLcServiceDrawer();
      });

      $('html').on('click', function (event) {
        if (
          !$(event.target).hasClass('js-lc_service_drawer') &&
          !$(event.target).hasClass('js-live-chat')
        ) {
          closeLcServiceDrawer();
        }
      });
      $('.js-lc_beauty_advisor_btn').on('click', function (event) {
        var $this = $(this);
        var $triggerChat;

        event.preventDefault();
        $('.close', 'c-chat-header').trigger('click');
        closeLcServiceDrawer();
        var $lpmTrigger = $('.LPMcontainer img');

        if ($lpmTrigger.length) {
          $lpmTrigger.trigger('click');
          if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
            site.track.evtLink({
              event_name: 'live chat interaction',
              event_category: 'live chat interaction',
              event_action: 'chat type selection',
              event_label: 'Beauty Advisor'
            });
          }
          if ($this.hasClass('lp-chat')) {
            $triggerChat = $('.LPMcontainer img');
          } else if ($this.hasClass('tw-chat')) {
            $triggerChat = $('#toky_container img');
          }
          if ($triggerChat.length > 0) {
            $triggerChat.trigger('click');
          }
        }
      });
      $('.js-lc_order_status_btn').on('click', function (event) {
        event.preventDefault();
        initCSChat();
      });

      const urlParams = new URLSearchParams(window.location.search);
      const hideGNavParam = urlParams.get(Drupal.settings.hideGNav.param);
      const pathName = window.location.pathname;
      const hideGNavPath = Drupal.settings.hideGNav.path;
      var $liveChat = $('.js-live-chat');

      if( hideGNavParam && pathName.includes(hideGNavPath)) {
        $liveChat.addClass('hidden');
      } else {
        $liveChat.removeClass('hidden');
      }
    }
  };
})(jQuery, Drupal);
